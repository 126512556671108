<template>

<app-screen :centralised="true">

	<div class="completed-text" v-html="texts.co.text"></div>
	
	<template v-slot:buttons>

		<app-button text="Continue" v-on:click="onContinueClick" />

	</template>

</app-screen>

</template>

<script>

export default {

	methods: {

		onContinueClick: function() {

			this.$router.push({
				name: 'Feed'
			})

		}

	}

}

</script>

<style scoped>

.completed-text {
	text-align: center;
}

.completed-text >>> h2 {
	color: #414141;
	font-size: 24px;
	font-weight: bold;
	line-height: 36px;
	margin-bottom: 16px;
}

.completed-text >>> p {
	color: #414141;
	font-size: 16px;
	line-height: 22px;
	margin-bottom: 16px;
}

.completed-text >>> p:last-child {
	margin-bottom: 0px;
}

</style>
