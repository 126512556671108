export default {

	api: {
		session: 'session',
		login: 'login',
		tasks: {
			start: 'tasks/start',
			save: 'tasks/save'
		},
		leaderboard: 'leaderboard',
		feed: 'feed',
		logout: 'logout'
	},

	task: {
		photo: 1,
		video: 2,
		multi: 3,
		audio: 4,
		complete: 1,
		incomplete: 0
	},

	upload: {
		warning: 5000000
	}

}