<template>

<div class="rules">

	<div class="rules-title" v-html="texts.ob.rules.title"></div>

	<div class="rules-text" v-html="texts.ob.rules.text"></div>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.rules {
	width: 100%;
	max-height: 100%;
}

.rules-title {
	color: #414141;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	width: 100%;
	margin-bottom: 32px;
}

.rules-text >>> h2 {
	color: #414141;
	width: 100%;
	text-align: center;
	text-transform: uppercase;
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 5px;
}

.rules-text >>> p {
	color: #7F7F7F;
	width: 100%;
	text-align: center;
	font-size: 20px;
	line-height: 30px;
	margin-bottom: 32px;
}

</style>
