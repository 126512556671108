import Vue from 'vue'
import Router from 'vue-router'

import screenSplash from '@/components/screen/Splash'
import screenLogin from '@/components/screen/Login'
import screenWelcome from '@/components/screen/Welcome'
import screenTasks from '@/components/screen/Tasks'
import screenLeaderboard from '@/components/screen/Leaderboard'
import screenFeed from '@/components/screen/Feed'
import screenTask from '@/components/screen/Task'
import screenLocked from '@/components/screen/Locked'
import screenCountdown from '@/components/screen/Countdown'
import screenTerms from '@/components/screen/Terms'
import screenPrivacy from '@/components/screen/Privacy'
import screenRules from '@/components/screen/Rules'
import screenMessage from '@/components/screen/Message'
import screenLogout from '@/components/screen/Logout'
import screenCompleted from '@/components/screen/Completed'

Vue.use(Router)

export default new Router({
	mode: 'history',
	routes: [
		{ name: 'Splash', path: '/', component: screenSplash },
		{ name: 'Login', path: '/login', component: screenLogin },
		{ name: 'Welcome', path: '/welcome', component: screenWelcome },
		{ name: 'Tasks', path: '/tasks', component: screenTasks },
		{ name: 'Leaderboard', path: '/leaderboard', component: screenLeaderboard },
		{ name: 'Feed', path: '/feed', component: screenFeed },
		{ name: 'Task', path: '/task', component: screenTask },
		{ name: 'Locked', path: '/locked', component: screenLocked },
		{ name: 'Countdown', path: '/countdown', component: screenCountdown },
		{ name: 'Terms', path: '/terms', component: screenTerms },
		{ name: 'Privacy', path: '/privacy', component: screenPrivacy },
		{ name: 'Rules', path: '/rules', component: screenRules },
		{ name: 'Message', path: '/message', component: screenMessage },
		{ name: 'Logout', path: '/logout', component: screenLogout },
		{ name: 'Completed', path: '/completed', component: screenCompleted }
	]
})