<template>

<div class="intro">

	<img class="intro-earthday" src="@/assets/logo-earthday-white-2.png" />

	<div class="intro-text" v-html="text"></div>

	<app-button :text="texts.ob.intro.button" v-on:click="$emit('proceed')" class="intro-button" />
	
</div>

</template>

<script>

export default {

	computed: {

		text: function() {

			return this.texts.ob.intro.text.replace('{{firstname}}', this.$store.getters['session'].name.split(' ')[0])

		}

	}

}

</script>

<style scoped>

.intro {
	width: 100%;
	flex-direction: column;
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.intro-text {
	color: #fff;
	font-size: 16px;
	margin-bottom: 120px;
	flex-grow: 1;
}

.intro-earthday {
	width: calc(100% + 20px);
	height: auto;
}

.intro-button {
	width: 100%;
	flex-shrink: 0;
}

</style>
