<template>

<app-screen :centralised="true" background="blue">

	<img class="login-earthday" src="@/assets/logo-earthday-3.png" />

	<div class="login-intro" v-if="!is.newbie">
		
		Hi! Let's get started...

	</div>

	<div class="login-email">

		<app-input v-on:enter="onLoginClick" :tabindex="1" type="text" :disabled="is.checking" maxlength="6" placeholder="Employee ID" v-model="input.pin" />

	</div>

	<div class="login-confirm" v-if="response">

		<div class="login-confirm-content">
		
			<p>Hi! Is this you?<br/>{{ response.session.name }}</p>

			<div class="login-confirm-buttons">
			
				<app-button v-on:click="onConfirm" text="Yes" />
				<app-button v-on:click="onCancel" text="No" />

			</div>
		
		</div>

	</div>

	<app-button text="Login" class="login-button" :errored="is.errored" v-on:click="onLoginClick" :disabled="!input.pin" :loading="is.checking" />

	<p class="login-register-legal">By logging you agree to our<br/><router-link :to="{name: 'Terms'}">Terms of Service</router-link> and <router-link :to="{name: 'Privacy'}">Privacy Policy</router-link></p>

</app-screen>

</template>

<script>

import Vue from 'vue'

export default {

	data: function() {

		return {
			key: '',
			input: {
				pin: ''
			},
			is: {
				checking: false,
				errored: false
			},
			response: false
		}

	},

	methods: {

		onLoginClick: function() {

			if (this.input.pin) {

				this.is.checking = true

				this.$api.request(this.$constants.api.login, {
					pin: this.input.pin
				}).then(function(response) {

					this.is.errored = false

					Vue.set(this, 'response', response)

				}.bind(this), function() {

					this.is.errored = true
					this.is.checking = false

				}.bind(this))

			}

		},

		onCancel: function() {

			this.response = false
			this.is.checking = false
			this.input.pin = ''

			this.$api.request(this.$constants.api.logout)

		},

		onConfirm: function() {

			this.$store.commit('session', this.response.session)

			if (this.response.session.team) {

				this.$gtag.customMap({
					'dimension1': 'teamKey',
					'dimension2': 'teamName'
				})

				this.$gtag.set({
					'teamKey': this.response.session.team,
					'teamName': this.$_.findWhere(this.$store.getters['teams'], {
						id: this.response.session.team
					}).name
				})
							
			}

			if (this.response.session.firsttime) {

				this.$router.push({
					name: 'Welcome'
				})

			} else {

				this.$router.push({
					name: 'Tasks'
				})

			}

		}

	}

}

</script>

<style scoped>

.login-earthday {
	width: calc(100% + 20px);
	height: auto;
	margin-top: -60px;
}

.login-confirm {
	background-color: #fff;
	padding: 20px;
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.75);
	z-index: 5;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.login-confirm-content {
	background-color: #fff;
	padding: 20px;
	border-radius: 10px;
	width: 300px;
	text-align: center;
}

.login-confirm-content p {
	font-size: 20px;
	margin-bottom: 20px;
}

.login-confirm-buttons {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
}

.login-intro {
	font-size: 16px;
	line-height: 24px;
	color: #1D81D6;
	width: 100%;
	margin-bottom: 10px;
	text-align: center;
}

.login-email {
	padding: 0px;
	width: 300px;
}

.login-button {
	width: 300px;
	margin-top: 20px;
}

.login-register {
	padding: 8px 0px;
	width: 300px;
	height: 128px;
}

.login-register >>> .input {
	margin: 8px 0px;
}

.login-register-legal {
	font-size: 10px;
	line-height: 20px;
	color: #1D81D6;
	text-align: center;
	margin-top: 20px;
}

</style>
