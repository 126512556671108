<template>

<div class="summary">

	<div class="summary-info">
		
		<div class="summary-info-text">
			
			Your score

		</div>

		<div class="summary-info-time" v-if="limited">

			<div class="summary-info-time-text">time remaining</div>

			<div class="summary-info-time-countdown">

				<div>{{ deadline[0] }}<small>days</small></div>
				<div>:</div>
				<div>{{ deadline[1] }}<small>hours</small></div>
				<div>:</div>
				<div>{{ deadline[2] }}<small>mins</small></div>

			</div>

		</div>
		
	</div>
		
	<div class="summary-graph">

		<com-progress :completed-steps="progress" :total-steps="total" :diameter="140" circle-color="#BCE0FD" start-color="#1D81D6" stop-color="#1D81D6" :circle-width="16"></com-progress>

		<div class="summary-graph-text">
			{{ progress }}/{{ total }}<small>points</small>
		</div>

	</div>

</div>

</template>

<script>

import { ProgressCircle } from 'vue-progress-circle'

export default {

	components: {
		'com-progress': ProgressCircle
	},

	computed: {

		limited: function() {

			return this.$store.getters['session'].date.deadline

		},

		deadline: function() {

			var seconds = this.countdown(this.$store.getters['session'].date.deadline, true)

			var deadline = []

			deadline.push(Math.floor(seconds / (3600 * 24)))

			seconds -= deadline[0] * (3600 * 24)

			deadline.push(Math.floor(seconds / 3600))

			seconds -= deadline[1] * 3600

			deadline.push(Math.floor(seconds / 60))

			//seconds -= deadline[1] * 60

			//deadline.push(seconds)

			deadline = deadline.map(function(value) {

				return (value < 10) ? '0' + value.toString() : value.toString()

			})

			return deadline

		},

		progress: function() {

			return this.$store.getters['session'].count.points || 0

		},

		total: function() {

			return this.$_.reduce(this.$store.getters['tasks'], function(total, task) {

				return total + task.points

			}, 0)

		}

	}

}

</script>

<style scoped>

.summary {
	width: 100%;
	display: flex;
	flex-direction: row;
	padding-top: 16px;
	margin-bottom: 32px;
	flex-shrink: 0;
}

.summary-info {
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.summary-info-text {
	font-size: 20px;
	padding: 32px 0px;
	text-align: center;
	font-weight: bold;
	color: #414141;
	text-transform: uppercase;
}

.summary-info-time-text {
	text-transform: lowercase;
	color: #414141;
	font-size: 12px;
	margin-bottom: 14px;
	text-align: center;
}

.summary-info-time-countdown {
	color: #AAA9A9;
	font-size: 20px;
	text-align: center;
	font-weight: bold;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.summary-info-time-countdown div {
	padding: 0px 1px;
	text-align: center;
}

.summary-info-time-countdown div small {
	display: block;
	color: #414141;
	font-weight: 400;
	font-size: 12px;
}

.summary-graph {
	height: 140px;
	width: 140px;
}

.summary-graph-text {
	position: absolute;
	left: 50%;
	top: 50%;
	color: #7F7F7F;
	text-align: center;
	transform: translate(-50%, -50%);
	font-size: 20px;
	font-weight: bold;
}

.summary-graph-text small {
	display: block;
	font-size: 10px;
	text-transform: uppercase;
	margin-top: 4px;
}

</style>
