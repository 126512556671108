<template>

<div class="photo">

	<div class="photo-capture" v-show="is.capturing">

		<div class="photo-back" v-on:click="onBackClick"><i class="fa fa-chevron-left"></i></div>

		<div class="audio-item-button-wrapper">

			<div class="audio-item-button is-record">
				<i class="fa fa-microphone"></i>
			</div>

			<div class="audio-item-button is-volume" :style="{height: this.volume.level + '%'}" v-if="is.recording">
				<div class="audio-item-button-inner">
					<i class="fa fa-microphone"></i>
				</div>
			</div>

		</div>
			
		<app-button text="Start recording" class="photo-capture-button" v-on:click="onCaptureStart" v-if="!is.recording" />
		<app-button text="Stop recording" :loading="is.saving" class="photo-capture-button" v-on:click="onCaptureStop" v-if="is.recording || is.saving" />

	</div>

	<input ref="upload" type="file" accept=".mp3,.wav" v-on:change="onSelectedFile" class="photo-select-upload" />

	<div class="photo-preview" :class="{'is-previewing': audio}" v-on:click="onPreviewClick">

		<i class="fa fa-play" v-if="audio && !is.playing"></i>
		<i class="fa fa-stop" v-if="audio && is.playing"></i>

		<audio v-if="audio && !is.capturing && !is.selecting" ref="audio" v-on:ended="onPlayed" playsinline class="photo-preview-video">

			<source :src="audio" >

		</audio>

		<i class="fa fa-microphone" v-if="!audio"></i>

	</div>

	<div class="photo-options">

		<app-button text="Record audio" v-if="!audio && !window.is.mobile" :disabled="!device || saving" class="photo-options-item" v-on:click="onTakeClick" />
		<app-button text="Re-record audio" v-if="audio && !window.is.mobile" :disabled="!device || saving" class="photo-options-item" v-on:click="onTakeClick" />
		<app-button text="Record audio" v-if="window.is.mobile" :loading="is.reading" :disabled="saving" class="photo-options-item" v-on:click="onSelectClick" />
		<app-button text="Select audio..." v-if="!window.is.mobile" :loading="is.reading" :disabled="saving" class="photo-options-item" v-on:click="onSelectClick" />

	</div>

</div>

</template>

<script>

export default {

	props: ['saved', 'saving'],

	data: function() {

		return {
			audio: false,
			device: true,
			maxDuration: 60000,
			recorder: false,
			recording: false,
			timer: false,
			stream: false,
			chunks: [],
			volume: {
				microphone: false,
				node: false,
				analyser: false,
				level: 0
			},
			is: {
				capturing: false,
				selecting: false,
				reading: false,
				saving: false,
				recording: false,
				playing: false
			}
		}

	},

	created: function() {

		if (this.saved) {

			this.audio = this.saved.asset 

		}

	},

	beforeDestroy: function() {

		clearTimeout(this.timer)

		if (this.recorder) {

			this.stream.stop()

			this.recorder.removeEventListener('dataavailable', this.onRecording.bind(this))
			this.recorder.removeEventListener('stop', this.onStopped.bind(this))

			this.volume.microphone.disconnect()
			this.volume.analyser.disconnect()
			this.volume.node.disconnect()

			if (this.is.recording) {

				this.volume.node.removeEventListener('audioprocess', this.onProcessing.bind(this))

				this.recorder.stop()
				
			}

			if (this.recording) {

				this.recording.removeEventListener('ended', this.onPlayed.bind(this))

			}

		}

	},

	methods: {

		onBackClick: function() {

			this.is.capturing = false

		},

		onCaptureStart: function() {

			this.is.recording = true

			this.chunks = []

			this.volume.node.addEventListener('audioprocess', this.onProcessing.bind(this))

			this.recorder.start()

			this.timer = this.$_.delay(this.onCaptureStop.bind(this), this.maxDuration)

		},

		onCaptureStop: function() {

			this.volume.node.removeEventListener('audioprocess', this.onProcessing.bind(this))

			this.recorder.stop()

			this.is.capturing = false
			this.is.recording = false

		},

		onPreviewClick: function() {

			if (this.audio) {

				if (this.is.playing) {

					this.$refs.audio.stop()
					this.is.playing = false

				} else {

					this.$refs.audio.play()
					this.is.playing = true

				}

			} else {

				if (!this.window.is.mobile) {
					
					this.onTakeClick()

				} else {

					this.onSelectClick()

				}

			}

		},

		onPlayed: function() {

			this.is.playing = false

		},

		onProcessing: function() {

			var array = new Uint8Array(this.volume.analyser.frequencyBinCount)
			this.volume.analyser.getByteFrequencyData(array)

			var values = 0

			var length = array.length

			for (var i = 0; i < length; i++) {
				values += array[i]
			}

			var average = values / length;

			var rounded = Math.round(average)

			this.volume.level = (rounded > 100) ? 100 : rounded

		},

		onRecording: function(event) {

			this.chunks.push(event.data)

		},

		onStopped: function() {

			clearTimeout(this.timer)

			var blob = new Blob(this.chunks)

			var reader = new FileReader()

			reader.onloadend = function() {

				this.audio = reader.result 

				this.audio = reader.result.replace('data:application/octet-stream;', 'data:audio/wav;')


				this.is.selecting = false
				this.is.recording = false
				this.is.capturing = false

				this.$emit('selected', this.audio)

			}.bind(this)

			reader.readAsDataURL(blob)

		},

		onSelectedFile: function(e) {

			this.is.reading = true

			var file = e.target.files[0]

			var reader = new FileReader()

			reader.onloadend = function() {

				this.audio = reader.result 

				this.is.selecting = false
				this.is.reading = false

				this.$emit('selected', this.audio)

			}.bind(this)

			reader.readAsDataURL(file)

		},

		onTakeClick: function() {

			navigator.mediaDevices.getUserMedia({
				audio: true 
			}).then(function(stream) {

				this.stream = stream

				this.recorder = new MediaRecorder(stream)

				this.recorder.addEventListener('dataavailable', this.onRecording.bind(this))
				this.recorder.addEventListener('stop', this.onStopped.bind(this))

				var audioContext = new AudioContext()
				this.volume.analyser = audioContext.createAnalyser()

				this.volume.microphone = audioContext.createMediaStreamSource(stream)
				this.volume.node = audioContext.createScriptProcessor(2048, 1, 1)

				this.volume.analyser.smoothingTimeConstant = 0.8
				this.volume.analyser.fftSize = 1024

				this.volume.microphone.connect(this.volume.analyser)
				this.volume.analyser.connect(this.volume.node)

				this.volume.node.connect(audioContext.destination)

				this.is.capturing = true
				this.is.selecting = false

				this.onCaptureStart()

			}.bind(this))

		},

		onSelectClick: function() {

			this.is.capturing = false
			this.is.selecting = true

			this.$refs.upload.click()

		}

	}

}

</script>

<style scoped>

.photo-preview {
	background-color: #e4e4e4;
	width: 100%;
	height: 216px;
	border-radius: 9px;
	margin-bottom: 9px;
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	overflow: hidden;
}

.photo-preview.is-previewing {
	background-color: #237DC6;
	cursor: pointer;
}

.photo-preview .fa {
	color: #1D81D6;
	font-size: 96px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.photo-preview.is-previewing .fa {
	color: #fff;
}

.photo-preview-video {
	width: 100%;
	position: absolute;
	left: 50%;
	top: 50%;
	height: auto;
	transform: translate(-50%, -50%);
}

.photo-options {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.photo-options-item {
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: 0;
	margin: 0px 2px;
}

.photo-capture {
	position: fixed;
	background-color: #000;
	z-index: 10;
	top: 0px;
	left: 0px;
	width: 100%;
	bottom: 0px;
	height: 100%;
	display: flex;
    align-items: center;
}

.photo-capture-button {
	left: 10px;
	right: 10px;
	bottom: 10px;
	width: calc(100% - 20px);
	position: absolute;
	z-index: 2;
}

.photo-select-upload {
	display: none;
}

.photo-back {
	width: 48px;
	height: 48px;
	position: absolute;
	left: 0px;
	top: 0px;
	font-size: 18px;
	color: #fff;
	text-align: center;
	line-height: 48px;
	cursor: pointer;
	z-index: 2;
}

.audio-item-button-wrapper {
	width: 256px;
	height: 256px;
	transform: translate(-50%, -50%);
	position: absolute;
	left: 50%;
	top: 50%;
}

.audio-item-button {
	width: 256px;
	height: 256px;
	font-size: 256px;
	text-align: center;
	line-height: 256px;
	cursor: pointer;
	color: #237DC6;
	position: absolute;
	left: 0px;
	bottom: 0px;
	z-index: 1;
}

.audio-item-button-inner {
	width: 256px;
	height: 256px;
	position: absolute;
	left: 0px;
	bottom: 0px;
	font-size: 256px;
	text-align: center;
	line-height: 256px;
}

.audio-item-button:hover {
	color: #1b649e;
}

.audio-item.is-disabled .audio-item-button {
	color: #eee;
	pointer-events: none;
}

.audio-item.is-playing .audio-item-button {
	color: #FBB516;
	pointer-events: none;
}

.audio-item-button.is-volume {
	color: #fff;
	position: absolute;
	left: 0px;
	bottom: 0px;
	height: 0%;
	overflow: hidden;
	z-index: 2;
}

.audio-item-button.is-play {
	font-size: 80px;
}

</style>
